
import { Vue, Component } from "vue-property-decorator";
import { zoneStore } from "@/store/typed";
import { urlFromMedia } from "@/utils/UrlHelper";
import marked from "marked";
@Component({})
export default class Page extends Vue {
  get pageCode() {
    return this.$route.params.page;
  }

  get page() {
    return this.zone ? this.zone[this.pageCode] : undefined;
  }

  get coverImageUrl() {
    return urlFromMedia(this.page.cover, "large");
  }

  get htmlContent() {
    return marked(this.page.content);
  }

  get zone(): any {
    return zoneStore.zone;
  }

  get isPageLegit() {
    if (!this.zone) {
      return false;
    }
    return (
      this.page?.endsWith("_page") && this.zone[this.pageCode] !== undefined
    );
  }
}
